import { put } from 'redux-saga/effects'
import jwtDecode from "jwt-decode"
import { loginStart, loginSuccess, loginFailed } from '../actions'
import { loginEndpoint } from '../../utils'

export default function* loginSaga(action) {
    // extract username and password from payload
    const { username, password } = action.payload

    // dispath login start action
    yield put(loginStart())

    // send request
    const response = yield fetch(loginEndpoint, {
        method: 'POST',
        body: JSON.stringify({ username, password }),
        headers: { 'Content-Type': 'application/json' },
    })

    // handle error
    if(parseInt(response.status / 100, 10) !== 2){ 
        if(response.status === 429) 
            return yield put(loginFailed("Lutfen daha sonra tekrar deneyin"))
        return  yield put(loginFailed())
    }

    const json = yield response.json();
    const parsedToken = jwtDecode(json.access_token)
    
    const userId = parseInt(parsedToken.realm_access.roles[0])

    // handle error
    if (json.error) return yield put(loginFailed())

    // save token to local storage
    localStorage.removeItem('token-ratio')
    localStorage.setItem('token-ratio', json.access_token)

    localStorage.removeItem('token-ratio-expires')
    localStorage.setItem('token-ratio-expires', json.expires_in)

    // save userID to local storage
    localStorage.removeItem('user-ratio')
    localStorage.setItem('user-ratio', userId)
    
    // dispatch login success action
    yield put(loginSuccess(json.access_token, json.expires_in))
}
