import * as actions from '../actions/types'

const initialState = {
    miniSidebar: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.SET_MINI_SIDEBAR:
            return { ...initialState, miniSidebar: action.payload }
        default:  return state
    } 
}
