import { put, select } from "redux-saga/effects";
import {
  apiEndpoint,
  getAccessToken,
  fetchData,
  getParentType,
} from "../../utils";
import { getPathArray } from "../../api/map";
import pointInPolygon from "point-in-polygon";
import {
  fetchPersonnelBegin,
  fetchPersonnelEnd,
  fetchVehiclesBegin,
  fetchVehiclesEnd,
  fetchRegionsBegin,
  fetchRegionsEnd,
  fetchRoadsBegin,
  fetchRoadsEnd,
  fetchViolationsEnd,
  createRegionBegin,
  createRegionSuccess,
  createRegionFailed,
  createRoadBegin,
  createRoadSuccess,
  createRoadFailed,
  updateShapeBegin,
  updateShapeSuccess,
  updateShapeFailed,
  deleteShapeBegin,
  deleteShapeSuccess,
  deleteShapeFailed,
  getLocationHistoryBegin,
  getLocationHistorySuccess,
  fetchRegions,
  fetchRoads,
  createRoadInvalid,
} from "../actions";
import { toast } from "react-toastify";
import moment from "moment";

export function* fetchPersonnelSaga() {
  yield put(fetchPersonnelBegin());
  // const response = yield fetch(`${apiEndpoint}/stafflog?sort=-createdAt&count=1000`, {
  //     headers: {
  //         'ratio-auth': getAccessToken(),
  //     },
  // })
  // const collection = yield response.json()

  // const staffSet = new Set(collection.map(c => c.staffId))
  // const staffList = [...staffSet].filter(id => id !== null)

  // const staffs = yield Promise.all(staffList.map(staffId => fetchData(`/staff/${staffId}`)))
  // const activeStaffs = staffs.filter(({active}) => active)

  // const result = []
  // activeStaffs.forEach(({id}) => {
  //     result.push( collection.find(({ staffId }) => staffId === id))
  // })

  const result = yield fetchData("/stafflogdistinct");  
  result.sort((a,b) => a.operationId - b.operationId)

  yield put(fetchPersonnelEnd(result));
}

export function* fetchVehiclesSaga() {
  yield put(fetchVehiclesBegin());
  // const response = yield fetch(`${apiEndpoint}/vehiclelog?sort=-createdAt&count=1000`, {
  //     headers: {
  //         'ratio-auth': getAccessToken(),
  //     },
  // })
  // const collection = yield response.json()

  // const vehicleSet = new Set(collection.map(c => c.vehicleId))
  // const vehicleList = [...vehicleSet].filter(id => id !== null)

  // const vehicles = yield Promise.all(vehicleList.map(vehicleId => fetchData(`/vehicle/${vehicleId}`)))
  // const activeVehicles = vehicles.filter(({active,properties}) => active && properties ? properties.available : false)

  // const result = []
  // activeVehicles.forEach(({id}) => {
  //     result.push(collection.find(({ vehicleId }) => vehicleId === id))
  // })

  const result = yield fetchData("/vehiclelogdistinct");

  const distinctVehicleIds = [];

  result.forEach((log) => {
    if (!distinctVehicleIds.some((id) => id === log.vehicleId))
      distinctVehicleIds.push(log.vehicleId);
  });

  const vehicles = yield fetchData(
    `/vehicle?children=vehicle_type&${distinctVehicleIds.reduce(
      (string, id) => string + "id=" + id + "&",
      ""
    )}`
  );
  const idMappedVehicles = vehicles.reduce((json, vehicle) => {
    json[vehicle.id] = vehicle;
    return json;
  }, {});

  result.forEach(
    (log) =>
      (log.vehicleTypeId = getParentType(idMappedVehicles[log.vehicleId]))
  );
  
  result.sort((a,b) => a.operationId - b.operationId)

  yield put(fetchVehiclesEnd(result));
}

export function* saveRegionSaga(action) {
  yield put(createRegionBegin());
  action.payload.properties = action.payload.properties || {};
  const { file } = action.payload;
  let coords = [];

  if (!file) {
    coords = getPathArray(action.payload.area).map(({ lat, lng }) => [
      lng,
      lat,
    ]);
    coords.push(coords[0]);
  } else {
    const formData = new FormData();
    formData.append("gis", file);

    const response = yield fetch(`https://ratioback.com/convertor/upload`, {
      method: "POST",
      body: formData,
    });
    try {
      const { features } = yield response.json();
      let isPolygon = false;

      for (let i = 0; i < features.length; i++) {
        const type = features[i].geometry.type;
        if (type === "Polygon") {
          coords = features[i].geometry.coordinates[0].map(([lng, lat]) => [
            lng,
            lat,
          ]);
          isPolygon = true;
          break;
        }
      }
      if (!isPolygon)
        throw {
          message: "Dosya Bölge İçermiyor",
          type: "not-valid",
        };
    } catch (error) {
      toast.error(
        (error.type === "not-valid" && error.message) || "Geçersiz Dosya"
      );
      return yield put(createRegionFailed());
    }
  }

  const { properties } = action.payload;
  let oreLoad = 0;
  if (properties.gemRatio && action.payload.totalLoad)
    oreLoad = (action.payload.totalLoad * properties.gemRatio) / 100;

  delete properties.gemRatio;

  const payload = {
    name: action.payload.name,
    type: action.payload.type,
    totalLoad: action.payload.totalLoad,
    capacity: action.payload.capacity,
    hourlyWorkKg: action.payload.hourlyWorkKg,
    properties: JSON.stringify({ ...properties }),
    loc: {
      type: "Polygon",
      coordinates: [coords],
    },
    regionTypeId: action.payload.regionTypeId,
    oreLoad,
  };

  const response = yield fetch(`${apiEndpoint}/region`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "ratio-auth": getAccessToken(),
    },
    body: JSON.stringify(payload),
  });

  const data = yield response.json();

  if (response.status === 201) {
    yield put(createRegionSuccess({ name: data.name }));
    yield put(fetchRegions());
    yield put(fetchRoads());
  } else yield put(createRegionFailed());
}

export const roadRegionValidate = function(data, regions) {
  const { points } = data;

  var start = points[0];
  var end = points[points.length - 1];
  data.startRegion = -1;
  data.endRegion = -1;
  data = regions.reduce((prev, cur) => {
    if (pointInPolygon(start, cur.loc.coordinates[0]))
      prev.startRegion = cur.id;
    if (pointInPolygon(end, cur.loc.coordinates[0])) prev.endRegion = cur.id;
    return prev;
  }, data);
  if (data.startRegion == data.endRegion) {
    data.startRegion = data.endRegion = -1;
  }
  return data.startRegion == -1 || data.endRegion == -1 ? null : data;
};

export function* saveRoadSaga(action) {
  yield put(createRoadBegin());
  const { regions } = yield select((store) => store.map);
  const { file } = action.payload;
  let points;

  if (!file) {
    points = getPathArray(action.payload.road).map(({ lat, lng }) => [
      lng,
      lat,
    ]);
  } else {
    const formData = new FormData();
    formData.append("gis", file);

    const response = yield fetch(`https://ratioback.com/convertor/upload`, {
      method: "POST",
      body: formData,
    });

    try {
      const { features } = yield response.json();
      let isPolyline = false;

      for (let i = 0; i < features.length; i++) {
        const type = features[i].geometry.type;
        if (type === "LineString") {
          points = features[i].geometry.coordinates.map(([lng, lat]) => [
            lng,
            lat,
          ]);
          isPolyline = true;
          break;
        }
      }
      if (!isPolyline)
        throw {
          message: "Dosya Yol İçermiyor",
          type: "not-valid",
        };
    } catch (error) {
      toast.error(
        (error.type === "not-valid" && error.message) || "Geçersiz Dosya"
      );
      return yield put(createRegionFailed());
    }
  }
  //VALIDATE ROAD
  const road = roadRegionValidate({ ...action.payload, points }, regions)
  if (!road) {
    yield put(createRoadInvalid(action.payload));
    return;
  }

  road.properties = road.properties || {};
  const payload = {
    name: road.name,
    road: {
      type: "LineString",
      coordinates: points,
    },
    properties: JSON.stringify(road.properties),
    maxSpeed: road.maxSpeed,
    startRegion: road.startRegion,
    endRegion: road.endRegion,
  };

  const response = yield fetch(`${apiEndpoint}/route`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "ratio-auth": getAccessToken(),
    },
    body: JSON.stringify(payload),
  });

  const data = yield response.json();

  if (response.status === 201) {
    yield put(createRoadSuccess({ name: data.name }));
    yield put(fetchRoads());
  } else yield put(createRoadFailed());
}

export function* getLocationHistorySaga(action) {
  const { dateBegin, dateEnd } = action.payload.values;
  const start = new Date(
    dateBegin.getFullYear(),
    dateBegin.getMonth(),
    dateBegin.getDate(),
  );
  const end = new Date(
    dateEnd.getFullYear(),
    dateEnd.getMonth(),
    dateEnd.getDate(),
  );

  yield put(getLocationHistoryBegin());

  const response = yield fetch(
    `${apiEndpoint}/vehiclelogheatmap?createdBegin=${start.toISOString()}&createdEnd=${end.toISOString()}`,
    {
      headers: {
        "ratio-auth": getAccessToken(),
      },
    }
  );
  const collection = yield response.json();
  let { regions, roads } = yield select(state => state.map);

  // append density values to roads and regions
  const startDateMoment = moment(dateBegin);
  const endDateMoment = moment(dateEnd);
  const dateCount = Math.abs(endDateMoment.diff(startDateMoment, 'days') + 1);

  roads = roads.map(road => {
    const log = collection.route.find(({routeid}) => routeid === road.id);
    return {...road, trafficDensity: (log && log.numpoint) || 0, dateCount } 
  })
  regions = regions.map(region => {
    const log = collection.region.find(({regionid}) => regionid === region.id);
    return {...region, trafficDensity: (log && log.numpoint) || 0, dateCount }
  })
  
  const mergedLogs = [
    ...collection.route.map((route) => ({ ...route, loc: route.center })),
    ...collection.region.map((region) => ({ ...region, loc: region.center })),
  ];

  yield put(getLocationHistorySuccess({ logs: mergedLogs, roads, regions }));
}

export function* fetchRegionsSaga() {
  yield put(fetchRegionsBegin());
  const response = yield fetch(`${apiEndpoint}/region?sort=-createdAt`, {
    headers: {
      "ratio-auth": getAccessToken(),
    },
  });
  const collection = yield response.json();
  collection.forEach((c) => {
    if (typeof c.properties == "string")
      c.properties = JSON.parse(c.properties);
    c.properties.gemRatio = (c.oreLoad / c.totalLoad) * 100;
  });
  yield put(fetchRegionsEnd(collection.filter((c) => c.loc && c.loc.type && c.regionTypeId !== 8)));
}

export function* fetchRoadsSaga() {
  yield put(fetchRoadsBegin());
  const response = yield fetch(`${apiEndpoint}/route`, {
    headers: {
      "ratio-auth": getAccessToken(),
    },
  });
  const collection = yield response.json();
  collection.forEach((c) => {
    if (typeof c.properties === "string")
      c.properties = JSON.parse(c.properties);
  });
  yield put(fetchRoadsEnd(collection));
}

export function* fetchViolationsSaga() {
  yield put(fetchVehiclesBegin());
  const response = yield fetch(`${apiEndpoint}/ruleviolation`, {
    headers: {
      "ratio-auth": getAccessToken(),
    },
  });
  const collection = yield response.json();
  yield put(fetchViolationsEnd(collection));
}

export function* updateShapeSaga(action) {
  const {
    shape,
    name,
    properties,
    record,
    regionTypeId,
    maxSpeed,
    startRegion,
    endRegion,
    totalLoad,
    capacity,
    hourlyWorkKg,
  } = action.payload;

  yield put(updateShapeBegin());

  const endpoint = record.road ? "route" : "region";
  const coordinates = getPathArray(shape).map(({ lat, lng }) => [lng, lat]);
  if(!record.road)
    coordinates.push(coordinates[0]);

  let oreLoad = 0;
  const jsonProperties = JSON.parse(properties);
  if (jsonProperties.gemRatio && totalLoad)
    oreLoad = (totalLoad * jsonProperties.gemRatio) / 100;
  delete jsonProperties.gemRatio;
  const payload =
    endpoint === "route"
      ? {
          road: JSON.stringify({ ...record.road, coordinates: coordinates }),
          name,
          properties,
          maxSpeed,
          startRegion,
          endRegion,
        }
      : {
          loc: { ...record.loc, coordinates: [coordinates] },
          name,
          properties: JSON.stringify(jsonProperties),
          regionTypeId,
          oreLoad,
          totalLoad,
          capacity,
          hourlyWorkKg,
        };

  const response = yield fetch(`${apiEndpoint}/${endpoint}/${record.id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "ratio-auth": getAccessToken(),
    },
    body: JSON.stringify(payload),
  });

  const data = yield response.json();

  if (response.status === 200 || response.status === 204)
    yield put(updateShapeSuccess({ name: data.name }));
  else yield put(updateShapeFailed());
}

export function* deleteShapeSaga(action) {
  const { endpoint, record } = action.payload;

  yield put(deleteShapeBegin());

  if (endpoint === "region") {
    const missionResponses = yield Promise.all([
      fetchData(`/mission?startRegionId=${record.id}`),
      fetchData(`/mission?endRegionId=${record.id}`),
      fetchData(`/operationDetailed?status=0&`),
    ]);

    const startRegionMissions = missionResponses[0] && missionResponses[0].filter(m => m.status !== 0) ;
    const endRegionMissions = missionResponses[1] && missionResponses[1].filter(m => m.status !== 0);
    const draftMissions = missionResponses[2];

    for (let i = 0; i < startRegionMissions.length; i++)
      if (
        startRegionMissions[i].status !== 2 &&
        startRegionMissions[i].status !== 3 &&
        startRegionMissions[i].status !== 5
      ) {
        yield put(
          deleteShapeFailed(
            `Bu bölge GÖREVDE kullanıldığı için silinemez`
          )
        );
        return;
      }
    for (let i = 0; i < endRegionMissions.length; i++)
      if (
        endRegionMissions[i].status !== 2 &&
        endRegionMissions[i].status !== 3 &&
        endRegionMissions[i].status !== 5
      ) {
        yield put(
          deleteShapeFailed(
            `Bu bölge GÖREVDE kullanıldığı için silinemez`
          )
        );
        return;
      }
      
      if((draftMissions && draftMissions.filter(({ id, parent }) => parent < 0 || parent === id).length)){
        if(draftMissions.some(({missions}) => missions.some(({startRegionId, endRegionId}) => startRegionId === record.id || endRegionId === record.id))){
          yield put(
            deleteShapeFailed(
              `Bu bölge TASLAKTA kullanıldığı için silinemez`
            )
          );
          return;
        }
      }
  }

  const response = yield fetch(`${apiEndpoint}/${endpoint}/${record.id}`, {
    method: "DELETE",
    headers: { "ratio-auth": getAccessToken() },
  });

  if (response.status === 200 || response.status === 204)
    yield put(deleteShapeSuccess({ name: record.name }));
  else yield put(deleteShapeFailed());
}
