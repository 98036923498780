import { takeEvery, takeLatest } from "redux-saga/effects";
import loginSaga from "./auth";
import {
  fetchTasksSaga,
  createNewTaskSaga,
  pauseTaskSaga,
  stopTaskSaga,
} from "./tasks";
import { fetchVehicleListSaga, createVehicleSaga } from "./vehicles";
import { fetchPersonnelListSaga, createPersonnelSaga, checkBatteryStatusSaga } from "./personnels";
import { fetchNotificationsSaga, setNotificationsRead, fetchNotificationSettingsSaga, editNotificationSettingsSaga } from "./notification";
import fetchDescriptionSaga from "./description";
import * as actions from "../actions/types";
import {
  fetchPersonnelSaga,
  fetchVehiclesSaga,
  fetchRegionsSaga,
  fetchRoadsSaga,
  fetchViolationsSaga,
  saveRegionSaga,
  saveRoadSaga,
  getLocationHistorySaga,
  updateShapeSaga,
  deleteShapeSaga,
} from "./map";
import { fetchBlockModelListSaga, createBlockModelSaga, fetchSubModelListSaga, uploadSubModelSaga } from "./blockModel";
import { fetchReportsSaga, deleteReportSaga, createReportSaga } from "./report";

export function* watchAuth() {
  yield takeEvery(actions.INITIATE_LOGIN, loginSaga);
}

export function* watchMap() {
  yield takeEvery(actions.FETCH_PERSONNEL, fetchPersonnelSaga);
  yield takeEvery(actions.FETCH_VEHICLES, fetchVehiclesSaga);
  yield takeEvery(actions.FETCH_REGIONS, fetchRegionsSaga);
  yield takeEvery(actions.FETCH_ROADS, fetchRoadsSaga);
  yield takeEvery(actions.FETCH_VIOLATIONS, fetchViolationsSaga);
  yield takeEvery(actions.CREATE_REGION, saveRegionSaga);
  yield takeEvery(actions.CREATE_ROAD, saveRoadSaga);
  yield takeEvery(actions.GET_LOCATION_HISTORY, getLocationHistorySaga);
  yield takeEvery(actions.UPDATE_SHAPE, updateShapeSaga);
  yield takeEvery(actions.DELETE_SHAPE, deleteShapeSaga);
}

export function* watchBlockModel() {
  yield takeEvery(actions.FETCH_BLOCK_MODEL_LIST, fetchBlockModelListSaga);
  yield takeEvery(actions.FETCH_SUB_MODEL_LIST, fetchSubModelListSaga);
  yield takeEvery(actions.CREATE_BLOCK_MODEL, createBlockModelSaga);
  yield takeEvery(actions.UPLOAD_SUB_MODEL, uploadSubModelSaga);
}

export function* watchTasks() {
  yield takeEvery(actions.FETCH_TASKS, fetchTasksSaga);
  yield takeEvery(actions.CREATE_NEW_TASK, createNewTaskSaga);
  yield takeEvery(actions.PAUSE_TASK, pauseTaskSaga);
  yield takeEvery(actions.STOP_TASK, stopTaskSaga);
}

export function* watchReports() {
  yield takeLatest(actions.FETCH_REPORTS, fetchReportsSaga);
  yield takeEvery(actions.DELETE_REPORT, deleteReportSaga);
  yield takeEvery(actions.CREATE_REPORT, createReportSaga);
}

export function* watchVehicles() {
  yield takeEvery(
    actions.vehiclesTypes.FETCH_VEHICLE_LIST,
    fetchVehicleListSaga
  );
  yield takeEvery(actions.vehiclesTypes.CREATE_VEHICLE, createVehicleSaga);
}

export function* watchPersonnels() {
  yield takeEvery(
    actions.personnelTypes.FETCH_PERSONNEL_LIST,
    fetchPersonnelListSaga
  );
  yield takeEvery(actions.personnelTypes.CREATE_PERSONNEL, createPersonnelSaga);
  yield takeEvery(actions.personnelTypes.CHECK_CHARGES, checkBatteryStatusSaga);
}

export function* watchNotifications() {
  yield takeEvery(actions.FETCH_NOTIFICATIONS, fetchNotificationsSaga);
  yield takeLatest(actions.SET_NOTIFICATIONS_READ, setNotificationsRead);
  yield takeLatest(actions.FETCH_NOTIFICATION_SETTINGS, fetchNotificationSettingsSaga);
  yield takeLatest(actions.EDIT_NOTIFICATION_SETTINGS, editNotificationSettingsSaga);
}

export function* watchDescriptions() {
  yield takeEvery(actions.FETCH_STAFF_TYPES, fetchDescriptionSaga);
}
