import { put, select, take, call } from "redux-saga/effects";
import {
  fetchPersonnelList,
  fetchPersonnelListBegin,
  fetchPersonnelListEnd,
  createPersonnelBegin,
  createPersonnelSuccess,
  createPersonnelFailed,
  checkChargesEnd,
} from "../actions";
import { apiEndpoint, getAccessToken, fetchData } from "../../utils";
import { toast } from "react-toastify";
import {
  appendNotifications,
  fetchNotificationSettings,
} from "../actions/notification";
import * as actions from "../actions/types";

export function* fetchPersonnelListSaga() {
  yield put(fetchPersonnelListBegin());
  const personnelResponse = yield fetch(
    `${apiEndpoint}/staff?active=true&count=1000&children=staff_type|shift_type|staff_tag`,
    {
      headers: {
        "ratio-auth": getAccessToken(),
      },
    }
  );
  const logResponse = yield fetch(`${apiEndpoint}/stafflogdistinct`, {
    headers: {
      "ratio-auth": getAccessToken(),
    },
  });
  const personnelList = yield personnelResponse.json();
  const logs = yield logResponse.json();

  logs.forEach((log) =>
    personnelList.forEach((personnel) => {
      if (log.staffId === personnel.id)
        personnel.charge = log.extra && log.extra.charge; //log.extra && log.extra.charge * 12.5 - 425;
    })
  );

  personnelList.sort(
    (a, b) =>
      (a.staff_tag && a.staff_tag.gatewayTagId) -
      (b.staff_tag && b.staff_tag.gatewayTagId)
  );

  yield put(fetchPersonnelListEnd(personnelList));
}

export function* createPersonnelSaga(action) {
  const { personnelConfig } = action.payload;
  yield put(createPersonnelBegin());

  if (personnelConfig.chiefId)
    personnelConfig.properties = { chiefId: personnelConfig.chiefId }

  const response = yield fetch(`${apiEndpoint}/staff`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "ratio-auth": getAccessToken(),
    },
    body: JSON.stringify(personnelConfig),
  });
  const parsedResponse = yield response.json();

  if (parsedResponse.errors) yield put(createPersonnelFailed());
  else yield put(createPersonnelSuccess(parsedResponse));

  yield put(fetchPersonnelList());
}

export function* checkBatteryStatusSaga() {
  const { loadingSettings } = yield select((store) => store.notifications);
  if (loadingSettings) return take(actions.FETCH_NOTIFICATION_SETTINGS_END);

  try {
    const { authenticated } = yield select((store) => store.auth);
    const { notificationSettings } = yield select(
      (store) => store.notifications
    );
    const { batteryNotifStatus } = yield select((store) => store.personnels);
    const ONE_HOUR = 60 * 60 * 1000;
    const newNotifs = [];

    if (authenticated) {
      const logs = yield fetchData("/stafflogdistinct");

      for (let i = 0; i < logs.length; i++) {
        const { staffId, extra } = logs[i];

        if (!batteryNotifStatus[staffId])
          batteryNotifStatus[staffId] = { notified: false };

        if (extra.charge < 40) {
          if (
            !batteryNotifStatus[staffId].notified &&
            (!batteryNotifStatus[staffId].lastTime ||
              new Date() - batteryNotifStatus[staffId].lastTime > ONE_HOUR)
          ) {
            const personnel = yield fetchData(`/staff/${staffId}`);
            const personnelName = `${personnel.name ||
              ""} ${personnel.surname || ""}`;

            batteryNotifStatus[staffId].notified = true;
            batteryNotifStatus[staffId].lastTime = new Date();

            newNotifs.push({
              id: `low-battery-personnel-${personnel.id}`,
              data: `Düşük Batarya - ${personnelName}`,
              type: "staff",
              notificationShowType: parseInt(
                notificationSettings.batteryNotificationType
              ),
              notifType: toast.TYPE.ERROR,
            });

            // toast(`Düşük Batarya - ${personnelName}`, {
            //   toastId: `low-battery-${staffId}`,
            //   type: toast.TYPE.ERROR,
            //   autoClose: false,
            // });
          }
        } else
          batteryNotifStatus[staffId] = {
            notified: false,
            lastTime: new Date(),
          };
      }
    }

    yield put(appendNotifications(newNotifs));
    yield put(checkChargesEnd(batteryNotifStatus));
  } catch (e) {
    console.log(e.message);
  }
}
