import { put } from "redux-saga/effects";
import {
  fetchBlockModelList,
  fetchBlockModelListBegin,
  fetchBlockModelListEnd,
  createBlockModelBegin,
  createBlockModelSuccess,
  createBlockModelFailed,
  fetchSubModelListBegin,
  fetchSubModelListEnd,
  uploadSubModelBegin,
  fetchSubModelList,
  uploadSubModelSuccess,
  uploadSubModelFailed,
} from "../actions";
import { getAccessToken, fetchData } from "../../utils";
import { toast } from "react-toastify";

export function* fetchBlockModelListSaga() {
  yield put(fetchBlockModelListBegin());

  const blockModelResponse = yield fetchData(
    `/BlockModel`, 'https://ratioback.com'
  );

  yield put(fetchBlockModelListEnd(blockModelResponse));
}


export function* fetchSubModelListSaga({ payload }) {
  yield put(fetchSubModelListBegin());
  const { blockModelId } = payload;

  const subModelResponse = yield fetchData(
    `/BlockModel/${blockModelId}/submodels`, 'https://ratioback.com'
  );

  yield put(fetchSubModelListEnd(subModelResponse));
}


export function* createBlockModelSaga(action) {
  const { blockModelConfig } = action.payload;
  yield put(createBlockModelBegin());

  const response = yield fetch(`https://ratioback.com/BlockModel/create`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "ratio-auth": getAccessToken()
    },
    body: JSON.stringify(blockModelConfig)
  });
  const parsedResponse = yield response.json();

  if (parsedResponse.errors) yield put(createBlockModelFailed());
  else yield put(createBlockModelSuccess(parsedResponse));

  yield put(fetchBlockModelList());
}

export function* uploadSubModelSaga(action) {
  const { blockModelId, file } = action.payload;
  yield put(uploadSubModelBegin());

  const formData = new FormData();
  formData.append("blockmodel", file)

  const response = yield fetch(`https://ratioback.com/BlockModel/upload/${blockModelId}`, {
    method: "POST",
    body: formData
  });
  const parsedResponse = yield response.json();

  if (parsedResponse.error) {
    toast.error(parsedResponse.message)
    yield put(uploadSubModelFailed(parsedResponse.message));
  }
  else yield put(uploadSubModelSuccess(parsedResponse));

  yield put(fetchSubModelList(blockModelId));
}
