import { put } from "redux-saga/effects";
import { fetchReportsBegin, fetchReportsEnd, deleteReportBegin, fetchReports, createReportBegin, createReportSuccess, createReportError } from "../actions/report";
import { fetchData, getAccessToken } from "../../utils";
import { toast } from "react-toastify";
import moment from "moment"

export function* fetchReportsSaga({payload = {}}) {
  if(payload.setLoading !== false)
    yield put(fetchReportsBegin());
  
  const reports = yield fetchData("/file-system/files/", "https://ratioback.com");
  
  if(payload.sort && payload.sort === "asc")
    reports.sort((a,b) => new Date(a.createddate) > new Date(b.createddate) ? 1 : -1);
  else
    reports.sort((a,b) => new Date(a.createddate) < new Date(b.createddate) ? 1 : -1);
  
  yield put(fetchReportsEnd(reports));
}


export function* deleteReportSaga({payload}) {
  yield put(deleteReportBegin());
  const { reportId, curSort } = payload;

  yield fetch(`https://ratioback.com/file-system/files/${reportId}`, {
    method: "DELETE",
    headers: {
      "ratio-auth": getAccessToken(),
    },
  });
  
  yield put(fetchReports({sort: curSort}));
};

export function* createReportSaga({payload, curSort}) {
  yield put(createReportBegin());

  const body = JSON.stringify({
    ...payload, 
    extension: "xlsx", 
    file_name: `${payload.file_type}-${new Date().toISOString()}`,
    beginDate:  moment(payload.beginDate).format('YYYY-MM-DD'),
    endDate:  moment(payload.endDate).format('YYYY-MM-DD'),
  })

  const response = yield fetch('https://ratioback.com/file-system/insert-by-callback', {
    method: "POST",
    body,
    headers: {
      "ratio-auth": getAccessToken(),
      "Content-Type": "application/json",
    },
  });

  if(response.status !== 200) {
    toast.error("Rapor oluşturulamadı!")
    put(createReportError());
  }
  
  yield put(fetchReports({sort: curSort}));
};


