import * as actionTypes from './types'

export const fetchReports = payload => ({ type: actionTypes.FETCH_REPORTS, payload })
export const fetchReportsBegin = () => ({ type: actionTypes.FETCH_REPORTS_BEGIN })
export const fetchReportsEnd = payload => ({ type: actionTypes.FETCH_REPORTS_SUCCESS, payload })

export const deleteReport = payload => ({ type: actionTypes.DELETE_REPORT, payload })
export const deleteReportBegin = () => ({ type: actionTypes.DELETE_REPORT_BEGIN })
export const deleteReportSuccess = () => ({ type: actionTypes.DELETE_REPORT_SUCCESS })
export const deleteReportError = () => ({ type: actionTypes.DELETE_REPORT_ERROR })

export const createReport = (payload, {curSort}) => ({ type: actionTypes.CREATE_REPORT, payload, curSort })
export const createReportBegin = () => ({ type: actionTypes.CREATE_REPORT_BEGIN })
export const createReportSuccess = () => ({ type: actionTypes.CREATE_REPORT_SUCCESS })
export const createReportError = () => ({ type: actionTypes.CREATE_REPORT_ERROR })