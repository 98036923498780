import * as types from './types'
import notification from '../reducers/notification'

export const fetchNotifications = () => ({ type: types.FETCH_NOTIFICATIONS })
export const fetchNotificationsBegin = () => ({ type: types.FETCH_NOTIFICATIONS_BEGIN })
export const fetchNotificationsEnd = notifications => ({ type: types.FETCH_NOTIFICATIONS_END, payload: { notifications } })

export const setNotificationsRead = notifications => ({ type: types.SET_NOTIFICATIONS_READ, payload: { notifications } })
export const setNotificationsReadBegin = () => ({ type: types.SET_NOTIFICATIONS_READ_BEGIN })
export const setNotificationsReadEnd = () => ({ type: types.SET_NOTIFICATIONS_READ_END })

export const appendNotifications = notifications => ({ type: types.APPEND_NOTIFICATIONS, payload: notifications })

export const fetchNotificationSettings = () => ({ type: types.FETCH_NOTIFICATION_SETTINGS })
export const fetchNotificationSettingsBegin = () => ({ type: types.FETCH_NOTIFICATION_SETTINGS_BEGIN })
export const fetchNotificationSettingsEnd = ({notificationSettings, alarmSettings}) => ({ type: types.FETCH_NOTIFICATION_SETTINGS_END, payload: {notificationSettings, alarmSettings} })

export const editNotificationSettings = notificationConfig => ({ type: types.EDIT_NOTIFICATION_SETTINGS, payload: notificationConfig })
export const editNotificationSettingsBegin = () => ({ type: types.EDIT_NOTIFICATION_SETTINGS_BEGIN })
export const editNotificationSettingsSuccess = notificationSettings => ({ type: types.EDIT_NOTIFICATION_SETTINGS_SUCCESS, payload: notificationSettings })
export const editNotificationSettingsError = () => ({ type: types.EDIT_NOTIFICATION_SETTINGS_ERROR })
