import styled, { createGlobalStyle } from "styled-components";

export const theme = {
  blue: "#1f30a6",
  black: "#393939",
  gray: "#898989",
  green: "#4caf50",
  lime: "#a0d911",
  yellow: "#ffeb00",
  red: "#e51a1a",
  gold: "#faad14",
  midnight: "#002766",
  teal: "#009688",
  orange: "#ff7043",
  purple: "#5e6de8",
  magenta: "#FF00FF",
  lightgreen: "#81c784",
  darkgreen: "#43A047",
  lightgray: "#e1e1e1",
  accentLight: "#91ddf9",
  accentMedium: "#0daae4",
  accentDark: "#065674",
  accentDarkTransparent: "rgba(6, 86, 116, 0.8)",
  contentText: "#32325d",
  blackTransparent: "rgba(57, 57, 57, 0.4)",
  bs: "0 12px 24px 0 rgba(0, 0, 0, 0.09)",
  bsDark: "0 9px 18px 0 rgba(0, 0, 0, 0.19)",
};

export const StyledPage = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: linear-gradient(
    to right bottom,
    ${theme.accentDark} 0%,
    ${theme.accentMedium} 80%
  );
  background-repeat: no-repeat;
  background-size: 100% 33%;
`;

export const GlobalStyle = createGlobalStyle`
    *, *::before, *::after {
        padding: 0;
        margin: 0;
        box-sizing: inherit;
    }
    body {
        font-family: 'Open Sans', sans-serif;
        box-sizing: border-box;
        font-weight: 400;
        color: ${theme.black};
        a {
            text-decoration: none;
            color: ${theme.black};
        }
    }

    ::-webkit-scrollbar {
      width: 6px;
      background-color: #e2f7ff;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #0daae4;
      border-radius: 4px;
    }

    .gmnoprint,
    .gm-style-cc {
        display: none;
    }

    @media screen and (max-width: 50em) {
        html {
            font-size: 80%;
        }
    }
    #menu-regionTypeId{
        z-index:10000 !important;
    }

    .modal-button-container {
        display: flex;
        justify-content: center;
        .MuiButtonBase-root {
            margin: 10px;
        }
    }

    .hidden{
      display: none !important;
    }

    .spin {
        animation: spin 1s linear infinite;
    }
    @keyframes spin {
        100% {
            transform: rotate(360deg);
        }
    }

    .attention {
      animation: attention 1s infinite;
    }
    @keyframes attention {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.1);
      }
      100% {
        transform: scale(1);
      }
    }

    .shake {
        animation: shake 1s linear infinite;
    }
    @keyframes shake {
      0% {
        transform: translate(1px, 1px) rotate(0deg);
      }
      10% {
        transform: translate(-1px, -2px) rotate(-1deg);
      }
      20% {
        transform: translate(-3px, 0px) rotate(1deg);
      }
      30% {
        transform: translate(3px, 2px) rotate(0deg);
      }
      40% {
        transform: translate(1px, -1px) rotate(1deg);
      }
      50% {
        transform: translate(-1px, 2px) rotate(-1deg);
      }
      60% {
        transform: translate(-3px, 1px) rotate(0deg);
      }
      70% {
        transform: translate(3px, 1px) rotate(-1deg);
      }
      80% {
        transform: translate(-1px, -1px) rotate(1deg);
      }
      90% {
        transform: translate(1px, 2px) rotate(0deg);
      }
      100% {
        transform: translate(1px, -2px) rotate(-1deg);
      }
    }
`;
