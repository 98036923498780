import React from "react";
import {useSelector, useDispatch} from "react-redux"
import { NavLink } from "react-router-dom";
import {
  FaHome,
  FaMapMarkedAlt,
  FaGem,
  FaTasks,
  FaChartPie,
  FaTruck,
  FaUserAlt,
  FaClipboardList,
  FaCog,
  FaBell,
  FaSignOutAlt,
} from "react-icons/fa";
import { GiStoneBlock } from "react-icons/gi";
import NavStyles from "./styles/NavStyles";
import { theme } from "./styles/Theme";
import Alarm from "../components/Alarm";
import * as actions from "../store/actions"
import logo from "../static/logo-default.png";

const activeStyle = {
  fontWeight: "700",
  color: theme.grey,
};

export default () => {
  const dispatch = useDispatch();
  const closed = useSelector(state => state.layout.miniSidebar);

  const toggleMenu = () => {
    dispatch(actions.setMiniSidebar(!closed))
  };

  return (
    <NavStyles closed={closed}>
      <div className="logo-container">
        <button type="button" className="nav-button" onClick={toggleMenu}>
          <span className="nav-icon" />
        </button>
        <img src={logo} alt="logo" />
      </div>

      <div className="menu-container">
        {closed || <div>
          <NavLink
            exact
            to="/"
            className="nav-link-container"
            activeStyle={activeStyle}
          >
            <FaHome />
            <p>Anasayfa</p>
            <span className="arrow-container">&gt;</span>
          </NavLink>
          <NavLink
            to="/map"
            className="nav-link-container"
            activeStyle={activeStyle}
          >
            <FaMapMarkedAlt />
            <p>Harita</p>
            <span className="arrow-container">&gt;</span>
          </NavLink>
          <NavLink
            to="/blockmodel"
            className="nav-link-container"
            activeStyle={activeStyle}
          >
            <GiStoneBlock />
            <p>Blok Model</p>
            <span className="arrow-container">&gt;</span>
          </NavLink>
          <NavLink
            to="/gem"
            className="nav-link-container"
            activeStyle={activeStyle}
          >
            <FaGem />
            <p>Cevher Yönetimi</p>
            <span className="arrow-container">&gt;</span>
          </NavLink>
          <NavLink
            to="/tasks"
            className="nav-link-container"
            activeStyle={activeStyle}
          >
            <FaTasks />
            <p>Operasyonlar</p>
            <span className="arrow-container">&gt;</span>
          </NavLink>
          <NavLink
            to="/metrics"
            className="nav-link-container"
            activeStyle={activeStyle}
          >
            <FaChartPie />
            <p>Metrikler</p>
            <span className="arrow-container">&gt;</span>
          </NavLink>
          <NavLink
            to="/vehicles"
            className="nav-link-container"
            activeStyle={activeStyle}
          >
            <FaTruck />
            <p>Araçlar</p>
            <span className="arrow-container">&gt;</span>
          </NavLink>
          <NavLink
            to="/personnels"
            className="nav-link-container"
            activeStyle={activeStyle}
          >
            <FaUserAlt />
            <p>Personel</p>
            <span className="arrow-container">&gt;</span>
          </NavLink>
          <NavLink
            to="/reports"
            className="nav-link-container"
            activeStyle={activeStyle}
          >
            <FaClipboardList />
            <p>Raporlar</p>
            <span className="arrow-container">&gt;</span>
          </NavLink>
          <NavLink
            to="/notifications"
            className="nav-link-container"
            activeStyle={activeStyle}
          >
            <FaBell />
            <p>Bildirimler</p>
            <span className="arrow-container">&gt;</span>
          </NavLink>
          <NavLink
            to="/settings"
            className="nav-link-container"
            activeStyle={activeStyle}
          >
            <FaCog />
            <p>Ayarlar</p>
            <span className="arrow-container">&gt;</span>
          </NavLink>
          <NavLink
            to="/logout"
            className="nav-link-container"
            activeStyle={activeStyle}
          >
            <FaSignOutAlt />
            <p>Çıkış</p>
            <span className="arrow-container">&gt;</span>
          </NavLink>
        </div>}
      </div>

      <Alarm visible={!closed}/>
    </NavStyles>
  );
};
