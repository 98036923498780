import React from "react";
import { format } from "date-fns";
import { theme } from "./components/styles/Theme";

export const apiEndpoint = "https://ratioback.com/v1";
export const loginEndpoint = "https://ratioback.com/v1/login";
export const defaultCenter = { lat: 38.943762, lng: 37.880554 };
export const defaultZoom = 15;
export const regionTypes = [
  { key: 1, value: "Cevher yükleme bölgesi" },
  { key: 2, value: "Cevher stok alanı" },
  { key: 3, value: "Dekapaj yükleme bölgesi" },
  { key: 4, value: "Dekapaj dökme bölgesi" },
  { key: 5, value: "Bunker" },
  { key: 6, value: "Delgi bölgesi" },
  { key: 7, value: "Geofence" },
  // { key: 8, value: "Global Zone" },
];
export const regionTypeMap = regionTypes.reduce((prev,{key,value}) => ({...prev, [key]: value}) ,{})
export const parentVehilceTypes = [
  { id: 1, name: "Kamyon" },
  { id: 2, name: "Ekskavator" },
  { id: 3, name: "Delici" },
  { id: 4, name: "Loader" },
  { id: 5, name: "Dozer" },
];

// FAALIYET GRAFIGI
export const truckLoaderActivites = {
  1: "Doldurma Bölgesinde",
  11: "Doluyor",
  12: "Komple Doldu",
  2: "Görev Yok",
  3: "Boşaltma Bölgesinde",
  31: "Boşaltılıyor",
  32: "Komple Boşaltıldı",
  5: "Boşaltıldı ve Yola Çıktı",
  4: "Doldu ve Yola Çıktı",
};
export const excavatorActivites = {
  1: "Çalışma Bölgesinde",
  2: "Görev Yok",
  3: "Dolduruluyor",
  31: "Doldurma Başladı",
  32: "Doldurma Bitti",
};
export const drillActivities = {
  1: "Delme İşlemi Yapılmıyor",
  2: "Deliyor",
  11: "Delme Başladı",
  12: "Delme Bitti",
};

// TODO: MERGE STATE FUNCTIONS [REFACTOR]
const BUFFER_LOAD = 2500; // TODO: Backendten alınmalı

export const getTruckMissionState = (state, load) => {
  state = parseInt(state)
  if(state === 2 && load > BUFFER_LOAD) return "Bosaltma bolgesine gidiliyor"
  if(state === 2 && load <= BUFFER_LOAD) return "Yukleme bolgesine gidiliyor"
  
  switch (parseInt(state)) {
    case 1:
    case 12:
      return load < BUFFER_LOAD ? "Yuklenmeyi Bekle" : "Bosaltma bolgesine ilerle";
    case 3:
    case 32:
      return load > BUFFER_LOAD ? "Yukunu Bosalt" : "Yukleme bolgesine ilerle";
    case 8:
      return "Yanlış Dökme"
    case 11:
      return "Yükleniyor";
    case 31:
      return "Boşaltılıyor";
    default:
      return "Yolda";
  }
};
export const getTruckState = (state, prevState, load) => {
  state = parseInt(state)
  if (state === 2 && prevState === 3 && load <= 1000) return "Yüksüz hareket";
  if (state === 2 && prevState === 31 && load >= 1000) return "Yüklü hareket";
  
  switch (parseInt(state)) {
    case 1:
    case 12:
      return "Yükleme Bölgesinde";
    case 3:
    case 32:
      return "Boşaltma Bölgesinde";
    case 8:
      return "Yanlış Dökme"
    case 11:
      return "Yükleniyor";
    case 31:
      return "Boşaltılıyor";
    default:
      return "Yolda";
  }
};
export const reduceTruckActivities = (activities) => {
  if(!activities || !activities.length) return [];

  const activityMap = activities.reduce((prev, {type,count}) => {
    count = parseInt(count)
    switch (parseInt(type)) {
      case 1:
      case 12:
        return {...prev, 1: (prev[1] + count) || count};
      case 3:
      case 32:
        return {...prev, 3: (prev[3] + count) || count};
      case 8:
        return {...prev, 8: count};
      case 11:
        return {...prev, 11: count};
      case 31:
        return {...prev, 31: count};
      default:
        return {...prev, "-1": (prev["-1"] + count) || count};
    }
  },{})
  const activitiesArr = Object.keys(activityMap).map(type => ({type: type, count: activityMap[type]}));
  return activitiesArr
}

export const getLoaderMissionState = getTruckMissionState
export const getLoaderState = (state) => {
  state = parseInt(state)
  if(state === 1 || state === 12) return "Yükleme Bölgesinde";
  return "Yolda";
}
export const reduceLoaderActivities = (activities) => {
  if(!activities || !activities.length) return [];

  const activityMap = activities.reduce((prev, {type,count}) => {
    switch (parseInt(type)) {
      case 1:
      case 12:
        return {...prev, 1: (prev[1] + count) || count};
      default:
        return {...prev, "-1": (prev["-1"] + count) || count};
    }
  },{})
  const activitiesArr = Object.keys(activityMap).map(type => ({type: type, count: activityMap[type]}));
  return activitiesArr
}

export const getExcavatorMissionState = (state) => {
  state = parseInt(state)
  if(state === 1) return "Yükleme bölgesinde"
  return "Yolda"
};
export const getExcavatorState = (state) => {
  switch (parseInt(state)) {
    case 1:
      return "Çalışma Bölgesinde";
    case 3:
    case 31:
    case 32:
      return "Yüklüyor";
    case 6:
      return "Araç Yüklendi"
    default:
      return "Yolda";
  }
};
export const reduceExcavatorActivities = (activities) => {
  if(!activities || !activities.length) return [];

  const activityMap = activities.reduce((prev, {type,count}) => {
    count = parseInt(count)
    switch (parseInt(type)) {
      case 1:
        return {...prev, 1: (prev[1] + count) || count};
      case 3:
      case 31:
      case 32:
        return {...prev, 3: (prev[3] + count) || count};
      case 6:
        return {...prev, 6: (prev[6] + count) || count};
      default:
        return {...prev, "-1": (prev["-1"] + count) || count};
    }
  },{})
  const activitiesArr = Object.keys(activityMap).map(type => ({type: type, count: activityMap[type]}));
  return activitiesArr
}

export const getDrillState = (state) => {
  switch (parseInt(state)) {
    case 1:
      return "Deliyor";
    case 0:
      return "Delmiyor";
    default:
      return "Yolda";
  }
};
export const getDrillMissionState = getDrillState

export const reduceDrillActivities = (activities) => {
  if(!activities || !activities.length) return [];

  const activityMap = activities.reduce((prev, {type,count}) => {
    count = parseInt(count)
    switch (parseInt(type)) {
      case 1:
        return {...prev, 1: (prev[1] + count) || count};
      case 0:
        return {...prev, 0: (prev[0] + count) || count};
      default:
        return {...prev, "-1": (prev["-1"] + count) || count};
    }
  },{})
  const activitiesArr = Object.keys(activityMap).map(type => ({type: type, count: activityMap[type]}));
  return activitiesArr
}

export const notificationTypes = [
  { name: "Pop-up Bildirim", key: "pop-up", value: 1 },
  { name: "Alt Bildirim", key: "alt", value: 2 },
  { name: "Standart Bildirim", key: "standart", value: 3 },
];

export const getPersonnelTypePath = (type) => {
  switch (type) {
    case 1: // truck driver
    case 5: // driver
      return "/personnels/detail/driver";
    case 4: // greyder operator
    case 2: // excavator operator
      return "/personnels/detail/operator";
    case 3: // drill operator
      return "/personnels/detail/operatordrill";
    default:
      return "/personnels/detail/driver";
  }
};
export const getVehicleTypePath = (type) => {
  switch (type) {
    case 1:
    case "1":
      return "/vehicles/detail/truck";
    case 4:
    case "4":
      return "/vehicles/detail/loader";
    case 2:
    case "2":
      return "/vehicles/detail/excavator";
    case 3:
    case "3":
      return "/vehicles/detail/drill";
    default:
      return "/vehicles/detail/other";
  }
};

export const getParentType = (vehicle) => {
  const { vehicle_type } = vehicle;
  const parent = vehicle_type && vehicle_type.parent;
  const vehicleTypeId = parent || vehicle.vehicleTypeId;

  return vehicleTypeId;
};

export const getMissionStatus = (state, prevState) => {
  if (state || prevState === null) return "-";

  if (prevState === 1 && state === 2) return "Yüklü Yola Çıkmış";
  if (prevState === 2 && state === 3) return "Boşaltılıyor";
  if (prevState === 3 && state === 2) return "Boş Yola Çıkmış";
  if (prevState === 2 && state === 1) return "Yükleniyor";
};

export const getAccessToken = () =>
  localStorage.getItem("token-ratio")
    ? localStorage.getItem("token-ratio").toString()
    : "";

export const getUserId = () => parseInt(localStorage.getItem("user-ratio"));

export const fetchData = async (url, api) => {
  try {
    const response = await fetch(`${api || apiEndpoint}${url}`, {
      headers: {
        "ratio-auth": getAccessToken(),
      },
    });
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

export const convertTon = (val) => {
  return parseFloat((parseInt(val) / 1000).toFixed(1));
}
export const convertKg = (val) => {
  return parseFloat(val.toString().replace(",",".")) * 1000
}

export const fixNumber = (number) => {
  if (!number && number !== 0) return null;

  const digitFixed = number.toFixed(2);
  return parseFloat(digitFixed);
};


export const getLastWeek = () => {
  const end = new Date();
  const start = new Date();
  //TEMPORARY
  start.setDate(start.getDate() - 6);

  return { start, end };
};
export const getLastMonth = () => {
  const end = new Date();
  const start = new Date();
  //TEMPORARY
  start.setDate(start.getDate() - 29);

  return { start, end };
};

export const getISODatesArray = (startDate, endDate) => {
  const dateArray = [];
  const currentDate = new Date(startDate);
  const stopDate = new Date(endDate);
  while (currentDate <= stopDate) {
    dateArray.push(format(currentDate, "yyyy-MM-dd"));
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dateArray;
};

export const CustomizedDot = (props) => {
  const { cx, cy, value } = props;

  if (value > 100) {
    return <circle cx={cx} cy={cy} r={7} fill="red" />;
  }
  return <circle cx={cx} cy={cy} r={5} fill="#66E8E2" />;
};

export const getSuccesColor = (success) => {
  if (success > 70) return theme.green;
  if (success > 40) return "#ff9900";
  return theme.red;
};

export const componentToHex = (c) => {
  var hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}

export const rgbToHex = (r, g, b) => {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export const getTrafficGradient = (weight, { type, dateCount }) => {
  const ROAD_MAX = 1500 * dateCount;
  const REGION_MAX = 5250 * dateCount;
  let percentage = 0;

  if(type === "road") percentage = weight * 100 / ROAD_MAX;
  else percentage = weight * 100 / REGION_MAX;

  if(percentage > 100) percentage = 100;
  
  const red = [255, 0, 0] 
  const green = [0, 255, 0] 

  var redDensity = percentage / 100;
  var greenDensity = 1 - redDensity;
  
  var rgb = [Math.round(green[0] * greenDensity + red[0] * redDensity),
      Math.round(green[1] * greenDensity + red[1] * redDensity),
      Math.round(green[2] * greenDensity + red[2] * redDensity)];

  return rgbToHex(rgb[0], rgb[1], rgb[2]);
}

export const generateHexColor = value => {
  if(value < 0) return "#FFF";

  var r = Math.floor(value / 256 / 256 % 256);
  var g = Math.floor(value / 256 % 256);
  var b = Math.floor(value % 256);

  return rgbToHex(r, g, b);
}