import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableFooter,
  TablePagination
} from "@material-ui/core";
import PaginationActions from "./PaginationActions";
import truncate from "../api/string";

const NotificationsTable = ({ setReadNotifications }) => {
  const [page, setPage] = useState(0);
  const [unreadedNotifications, setUnreadedNotifications] = useState([]);
  const { notifications } = useSelector(state => state.notifications);
  const history = useHistory();

  useEffect(() => {
    setUnreadedNotifications(notifications.filter(notif => !notif.isRead));
  }, [notifications, setUnreadedNotifications]);

  // handle page change
  const handlePageChange = (_, newPage) => setPage(newPage);

  const setReadAllNotifications = async () => {
    if (setReadNotifications) {
      await setReadNotifications();
    }
    await history.push("/notifications");
  };

  return (
    <Table size="small">
      <TableBody>
        {unreadedNotifications &&
          unreadedNotifications
            .slice(page * 10, page * 10 + 10)
            .map(({ id, data, updatedAt, type }) => (
              <TableRow
                key={`${type}-${id}`}
                hover
                onClick={() => history.push("/notifications")}
              >
                <TableCell>{data && truncate(data, 50)}</TableCell>
                <TableCell>
                  { updatedAt && format(new Date(updatedAt), "MMM d, yyyy hh:mm")}
                </TableCell>
              </TableRow>
            ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TablePagination
            count={unreadedNotifications.length}
            page={page}
            rowsPerPage={10}
            rowsPerPageOptions={[]}
            onChangePage={handlePageChange}
            ActionsComponent={PaginationActions}
          />
        </TableRow>
      </TableFooter>
    </Table>
  );
};

export default NotificationsTable;
