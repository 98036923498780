import { put } from "redux-saga/effects";
import {
  fetchTasksBegin,
  fetchTasksEnd,
  createNewTaskBegin,
  createNewTaskSuccess,
  createNewTaskFailed,
  pauseTaskBegin,
  pauseTaskSuccess,
  stopTaskBegin,
  stopTaskSuccess,
} from "../actions";
import { apiEndpoint, getAccessToken, fetchData, getParentType, convertKg } from "../../utils";

const getDefaultMissionType = (missionType) => {
  switch (missionType) {
    case 2:
      return "gem";
    case 3:
      return "stock";
    case 4:
      return "drill";
    default:
      return "gem";
  }
};

const setDefaultMissionFields = (mission) => {
  mission.startdate = Date.now();
  mission.enddate = Date.now();
  mission.expectedEnddate = null;
  mission.type = getDefaultMissionType(mission.missionType);
  mission.totalLoad = 0;
  mission.currentLoad = 0;
  mission.calculatedRound = 0;
  mission.state = 1;
  mission.prevState = 0;
  mission.history = JSON.stringify({});
  mission.extra = JSON.stringify({ ratio: 0.0 });
  return mission;
};

export function* fetchTasksSaga() {
  yield put(fetchTasksBegin());
  const ongoingResponse = yield fetch(
    `${apiEndpoint}/operationDetailed?status=1&sort=-createdAt`,
    {
      headers: {
        "ratio-auth": getAccessToken(),
      },
    }
  );
  let onGoingTasks = yield ongoingResponse.json();

  const finishedTasksResponse = yield fetch(
    `${apiEndpoint}/operationDetailed?status=2&status=3&status=5&sort=-createdAt&count=10`,
    {
      headers: {
        "ratio-auth": getAccessToken(),
      },
    }
  );
  let finishedTasks = yield finishedTasksResponse.json();

  const onGoingMap = onGoingTasks.reduce(
    (prev, cur) => ({ ...prev, [cur.id]: cur }),
    {}
  );
  const finishedMap = finishedTasks.reduce(
    (prev, cur) => ({ ...prev, [cur.id]: cur }),
    {}
  );
  const taskMap = { ...onGoingMap, ...finishedMap };

  // CHECK IF PARENTTASK IS STILL ONGOING
  for (var i in onGoingTasks) {
    const parentId = onGoingTasks[i].parent;

    if (parentId !== -1 && parentId) {
      let parentTask = taskMap[parentId];
      if (!parentTask) yield fetchData(`/operationDetailed/${parentId}`);

      if (parentTask && (parentTask.status === 2 || parentTask.status === 3)) {
        parentTask.status = 1;
        finishedTasks = finishedTasks.filter(
          (task) => task.id !== parentTask.id
        );
        if (!onGoingTasks.find((task) => task.id === parentTask.id))
          onGoingTasks.push(parentTask);
      }
    }
  }

  yield put(fetchTasksEnd({ onGoingTasks, finishedTasks }));
}

export function* createNewTaskSaga(action) {
  const { task } = action.payload;

  yield put(createNewTaskBegin());

  const payloadOperation = {
    description: task.name,
    type: task.type,
    typeId: task.type === "gem" ? 2 : 4,
    status: task.summaryType === "draft" ? 0 : 1,
    active: true,
  };

  const responseOperation = yield fetch(`${apiEndpoint}/operation`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "ratio-auth": getAccessToken(),
    },
    body: JSON.stringify(payloadOperation),
  });

  const dataOperation = yield responseOperation.json();

  if (responseOperation.status !== 201) {
    yield put(createNewTaskFailed());
    return;
  }

  const missionData = [];
  const excMissions = []; // push to missionData
  const targetExc =
    task.missions[0] &&
    task.missions[0].excavator &&
    task.missions[0].excavator.id &&
    task.missions[0].target &&
    convertKg(task.missions[0].target[task.missions[0].excavator.id].target);

  task.missions.forEach((mission) => {
    if (
      !missionData.find(({ vehicleId }) => vehicleId === mission.excavator.id)
    ) {
      excMissions.push({
        status: dataOperation.status === 0 ? 0 : 1,
        targetLoad: task.type === "gem" ? targetExc : 0,
        targetDrill:
          task.type === "gem" ? 0 : parseInt(mission.drillTarget, 10),
        currentRound: 0,
        vehicleId: mission.excavator.id,
        operationId: dataOperation.id,
        startRegionId:
          task.type === "gem" ? mission.loadRegion.id : mission.drillRegion.id,
        endRegionId: task.type === "gem" ? mission.dumpRegion.id : null,
        missionType: task.type === "gem" ? 2 : 4,
        task: task.name,
      });
    }
    if (task.type === "gem") {
      mission.trucks.forEach((truck) => {
        missionData.push({
          status: dataOperation.status === 0 ? 0 : 1,
          targetLoad: convertKg(mission.target[truck.id].target),
          currentRound: 0,
          vehicleId: truck.id,
          operationId: dataOperation.id,
          startRegionId: mission.loadRegion.id,
          endRegionId: mission.dumpRegion.id,
          missionType: task.type === "gem" ? 2 : 4,
          task: task.name,
        });
      });
    }
  });
  missionData.forEach((m) => setDefaultMissionFields(m));
  excMissions.forEach((m) => setDefaultMissionFields(m));

  const missionRequests = missionData.map((payload) =>
    fetch(`${apiEndpoint}/mission`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "ratio-auth": getAccessToken(),
      },
      body: JSON.stringify(payload),
    })
  );
  const excMissionRequests = excMissions.map((payload) =>
    fetch(`${apiEndpoint}/mission`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "ratio-auth": getAccessToken(),
      },
      body: JSON.stringify(payload),
    })
  );

  yield Promise.all(missionRequests); // create truck misisons first
  yield Promise.all(excMissionRequests);

  if (
    task.stockMission.excavator &&
    task.stockMission.loadRegions.length > 0 &&
    task.stockMission.target
  ) {
    yield fetch(`${apiEndpoint}/operation/${dataOperation.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "ratio-auth": getAccessToken(),
      },
      body: JSON.stringify({ parent: dataOperation.id }),
    });

    const payloadStockOperation = {
      description: task.name,
      type: "stok",
      typeId: 3,
      status: task.summaryType === "draft" ? 0 : 1,
      active: true,
      parent: dataOperation.id,
    };

    const responseStockOperation = yield fetch(`${apiEndpoint}/operation`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "ratio-auth": getAccessToken(),
      },
      body: JSON.stringify(payloadStockOperation),
    });

    const dataStokOperation = yield responseStockOperation.json();

    const bunker = yield fetchData("/region?regionTypeId=5&count=1");

    const missionStokData = [];
    task.stockMission.loadRegions.forEach((region) => {
      missionStokData.push({
        status: dataStokOperation.status === 0 ? 0 : 1,
        targetLoad: convertKg(task.stockMission.target[region.id].target),
        currentRound: 0,
        vehicleId: task.stockMission.excavator.id,
        operationId: dataStokOperation.id,
        startRegionId: region.id,
        endRegionId: bunker[0] && bunker[0].id,
        missionType: 3,
        task: task.name,
      });
    });
    missionStokData.forEach((m) => setDefaultMissionFields(m));

    const missionStokRequests = missionStokData.map((payload) =>
      fetch(`${apiEndpoint}/mission`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "ratio-auth": getAccessToken(),
        },
        body: JSON.stringify(payload),
      })
    );

    yield Promise.all(missionStokRequests);
  }
  yield put(
    createNewTaskSuccess({ name: task.name, summaryType: task.summaryType })
  );
}

export function* pauseTaskSaga(action) {
  const { id } = action.payload;
  yield put(pauseTaskBegin())

  yield fetch(`${apiEndpoint}/operationStatus/${id}/2`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "ratio-auth": getAccessToken(),
    },
  });
  // const data = yield response.json()
  yield put(pauseTaskSuccess(id));
}

export function* stopTaskSaga(action) {
  const { id, parent, loader } = action.payload;
  yield put(stopTaskBegin());

  if (loader && loader.isLoaded === 1)
    yield fetch(`${apiEndpoint}/vehicle/${loader.id}`, {
      method: "PUT",
      body: JSON.stringify({ isLoaded: 0 }),
      headers: {
        "Content-Type": "application/json",
        "ratio-auth": getAccessToken(),
      },
    });

  yield fetch(`${apiEndpoint}/operationStatus/${id}/5`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "ratio-auth": getAccessToken(),
    },
  });

  if (parent > -1) {
    const stockOpsResponse = yield fetch(
      `${apiEndpoint}/operation?parent=${id}`,
      {
        headers: { "ratio-auth": getAccessToken() },
      }
    );
    const stockOps = yield stockOpsResponse.json();
    const stockOp = stockOps.find((op) => op.id !== parent);

    yield fetch(`${apiEndpoint}/operationStatus/${stockOp.id}/5`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "ratio-auth": getAccessToken(),
      },
    });
  }

  yield put(stopTaskSuccess(id));
}
