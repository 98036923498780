import { put, select } from "redux-saga/effects";
import { apiEndpoint, getAccessToken, getUserId, fetchData } from "../../utils";
import {
  fetchNotificationsBegin,
  fetchNotificationsEnd,
  setNotificationsReadBegin,
  setNotificationsReadEnd,
  fetchNotifications,
  fetchNotificationSettingsBegin,
  fetchNotificationSettingsEnd,
  editNotificationSettingsBegin,
  editNotificationSettingsSuccess,
} from "../actions";
import { toast } from "react-toastify";

export function* fetchNotificationsSaga() {
  if (isNaN(getUserId())) return;
  yield put(fetchNotificationsBegin());
  let [
    operationNotifs,
    missionNotifs,
    vehicleNotifs,
    staffNotifs,
  ] = yield Promise.all([
    fetchData(`/NotificationOperationByUser/${getUserId()}`),
    fetchData(`/NotificationMissionByUser/${getUserId()}`),
    fetchData(`/NotificationVehicleByUser/${getUserId()}`),
    fetchData(`/NotificationStaffByUser/${getUserId()}`),
  ]);

  operationNotifs = operationNotifs.map((notif) => ({
    ...notif,
    type: "operation",
  }));
  missionNotifs = missionNotifs.map((notif) => ({ ...notif, type: "mission" }));
  vehicleNotifs = vehicleNotifs.map((notif) => ({ ...notif, type: "vehicle" }));
  staffNotifs = staffNotifs.map((notif) => ({ ...notif, type: "staff" }));

  const collection = [
    ...operationNotifs,
    ...missionNotifs,
    ...vehicleNotifs,
    ...staffNotifs,
  ];
  collection.sort((a, b) =>
    a.updatedAt > b.updatedAt ? -1 : b.updatedAt > a.updatedAt ? 1 : 0
  );

  yield put(fetchNotificationsEnd(collection));
}

export function* setNotificationsRead(action) {
  if (isNaN(getUserId())) return;
  yield put(setNotificationsReadBegin());

  let { notifications } = action.payload;
  let lastIndex = {
    staff: null,
    vehicle: null,
    operation: null,
    mission: null,
  };

  notifications.forEach((notif) => {
    if (notif.id > lastIndex[notif.type]) lastIndex[notif.type] = notif.id;
  });

  const requestBody = {
    notificationMissionIndex: lastIndex.mission || undefined,
    notificationOperationIndex: lastIndex.operation || undefined,
    notificationStaffIndex: lastIndex.staff || undefined,
    notificationVehicleIndex: lastIndex.vehicle || undefined,
  };

  yield fetch(`${apiEndpoint}/user/${getUserId()}`, {
    method: "PUT",
    body: JSON.stringify(requestBody),
    headers: {
      "Content-Type": "application/json",
      "ratio-auth": getAccessToken(),
    },
  });

  yield put(setNotificationsReadEnd());
  //put(fetchNotifications());
}

//SETTINGS
export function* fetchNotificationSettingsSaga() {
  yield put(fetchNotificationSettingsBegin());

  const settingsResponse = yield fetchData("/notificationsetting");
  const notificationSettings = settingsResponse && settingsResponse[0];

  const storedAlarmSettings = JSON.parse(localStorage.getItem("alarm-settings")) || { personnelFall: true, alarm: true, button: true };
  const alarmSettings = {
    personnelFall: !!storedAlarmSettings.personnelFall,
    button: !!storedAlarmSettings.button,
    alarm: !!storedAlarmSettings.alarm,
  }
  yield put(fetchNotificationSettingsEnd({notificationSettings, alarmSettings}));
}

export function* editNotificationSettingsSaga(action) {
  yield put(editNotificationSettingsBegin());
  const { notificationSettings } = yield select((store) => store.notifications);
  const newNotifSettings = { ...notificationSettings, ...action.payload };

  const response = yield fetch(
    `${apiEndpoint}/notificationsetting/${notificationSettings.id}`,
    {
      method: "PUT",
      body: JSON.stringify(newNotifSettings),
      headers: {
        "Content-Type": "application/json",
        "ratio-auth": getAccessToken(),
      },
    }
  );

  const parsedResponse = yield response.json();
  if (parsedResponse.errors) {
    toast.error("Ayar Güncellenemedi");
  } else {
    toast.success("Güncellendi");
  }

  yield put(editNotificationSettingsSuccess(parsedResponse));
}
