// Layout Actions
export const SET_MINI_SIDEBAR = "SET_MINI_SIDEBAR";


// Login actions
export const INITIATE_LOGIN = "INITIATE_LOGIN";
export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGOUT = "LOGOUT";

// Map actions
export const FETCH_PERSONNEL = "FETCH_PERSONNEL";
export const FETCH_PERSONNEL_BEGIN = "FETCH_PERSONNEL_BEGIN";
export const FETCH_PERSONNEL_END = "FETCH_PERSONNEL_END";
export const FETCH_VEHICLES = "FETCH_VEHICLES";
export const FETCH_VEHICLES_BEGIN = "FETCH_VEHICLES_BEGIN";
export const FETCH_VEHICLES_END = "FETCH_VEHICLES_END";
export const FETCH_REGIONS = "FETCH_REGIONS";
export const FETCH_REGIONS_BEGIN = "FETCH_REGIONS_BEGIN";
export const FETCH_REGIONS_END = "FETCH_REGIONS_END";
export const FETCH_ROADS = "FETCH_ROADS";
export const FETCH_ROADS_BEGIN = "FETCH_ROADS_BEGIN";
export const FETCH_ROADS_END = "FETCH_ROADS_END";
export const FETCH_VIOLATIONS = "FETCH_VIOLATIONS";
export const FETCH_VIOLATIONS_BEGIN = "FETCH_VIOLATIONS_BEGIN";
export const FETCH_VIOLATIONS_END = "FETCH_VIOLATIONS_END";
export const CREATE_REGION = "CREATE_REGION";
export const CREATE_REGION_BEGIN = "CREATE_REGION_BEGIN";
export const CREATE_REGION_SUCCESS = "CREATE_REGION_SUCCESS";
export const CREATE_REGION_FAILED = "CREATE_REGION_FAILED";
export const CREATE_REGION_INITIALIZE = "CREATE_REGION_INITIALIZE";
export const UPDATE_SHAPE = "UPDATE_SHAPE";
export const UPDATE_SHAPE_INVALID = "UPDATE_SHAPE_INVALID";
export const UPDATE_SHAPE_BEGIN = "UPDATE_SHAPE_BEGIN";
export const UPDATE_SHAPE_SUCCESS = "UPDATE_SHAPE_SUCCESS";
export const UPDATE_SHAPE_FAILED = "UPDATE_SHAPE_FAILED";
export const UPDATE_SHAPE_INITIALIZE = "UPDATE_SHAPE_INITIALIZE";
export const DELETE_SHAPE = "DELETE_SHAPE";
export const DELETE_SHAPE_BEGIN = "DELETE_SHAPE_BEGIN";
export const DELETE_SHAPE_SUCCESS = "DELETE_SHAPE_SUCCESS";
export const DELETE_SHAPE_FAILED = "DELETE_SHAPE_FAILED";
export const DELETE_SHAPE_INITIALIZE = "DELETE_SHAPE_INITIALIZE";
export const CREATE_ROAD = "CREATE_ROAD";
export const CREATE_ROAD_BEGIN = "CREATE_ROAD_BEGIN";
export const CREATE_ROAD_SUCCESS = "CREATE_ROAD_SUCCESS";
export const CREATE_ROAD_FAILED = "CREATE_ROAD_FAILED";
export const CREATE_ROAD_INITIALIZE = "CREATE_ROAD_INITIALIZE";
export const CREATE_ROAD_INVALID = "CREATE_ROAD_INVALID";
export const GET_LOCATION_HISTORY = "GET_LOCATION_HISTORY";
export const GET_LOCATION_HISTORY_BEGIN = "GET_LOCATION_HISTORY_BEGIN";
export const GET_LOCATION_HISTORY_SUCCESS = "GET_LOCATION_HISTORY_SUCCESS";
export const GET_LOCATION_HISTORY_FAILED = "GET_LOCATION_HISTORY_FAILED";
export const CLEAR_LOCATION_HISTORY = "CLEAR_LOCATION_HISTORY";

// Block Model actions
export const FETCH_BLOCK_MODEL_LIST = "FETCH_BLOCK_MODEL_LIST";
export const FETCH_BLOCK_MODEL_LIST_BEGIN = "FETCH_BLOCK_MODEL_LIST_BEGIN";
export const FETCH_BLOCK_MODEL_LIST_END = "FETCH_BLOCK_MODEL_LIST_END";
export const FETCH_SUB_MODEL_LIST = "FETCH_SUB_MODEL_LIST";
export const FETCH_SUB_MODEL_LIST_BEGIN = "FETCH_SUB_MODEL_LIST_BEGIN";
export const FETCH_SUB_MODEL_LIST_END = "FETCH_SUB_MODEL_LIST_END";
export const SET_SELECTED_SUB_MODEL_LIST = "SET_SELECTED_SUB_MODEL_LIST";
export const CREATE_BLOCK_MODEL = "CREATE_BLOCK_MODEL";
export const CREATE_BLOCK_MODEL_BEGIN = "CREATE_BLOCK_MODEL_BEGIN";
export const CREATE_BLOCK_MODEL_SUCCESS = "CREATE_BLOCK_MODEL_SUCCESS";
export const CREATE_BLOCK_MODEL_FAILED = "CREATE_BLOCK_MODEL_FAILED";
export const RESET_BLOCK_MODEL = "RESET_BLOCK_MODEL";
export const UPLOAD_SUB_MODEL = "UPLOAD_SUB_MODEL";
export const UPLOAD_SUB_MODEL_BEGIN = "UPLOAD_SUB_MODEL_BEGIN";
export const UPLOAD_SUB_MODEL_SUCCESS = "UPLOAD_SUB_MODEL_SUCCESS";
export const UPLOAD_SUB_MODEL_FAILED = "UPLOAD_SUB_MODEL_FAILED";
export const RESET_UPLOAD_SUB_MODEL = "RESET_UPLOAD_SUB_MODEL";

// Task actions
export const FETCH_TASKS = "FETCH_TASKS";
export const FETCH_TASKS_BEGIN = "FETCH_TASKS_BEGIN";
export const FETCH_TASKS_END = "FETCH_TASKS_END";
export const CREATE_NEW_TASK = "CREATE_NEW_TASK";
export const CREATE_NEW_TASK_BEGIN = "CREATE_NEW_TASK_BEGIN";
export const CREATE_NEW_TASK_SUCCESS = "CREATE_NEW_TASK_SUCCESS";
export const CREATE_NEW_TASK_FAILED = "CREATE_NEW_TASK_FAILED";
export const CREATE_NEW_TASK_INITIALIZE = "CREATE_NEW_TASK_INITIALIZE";
export const PAUSE_TASK = "PAUSE_TASK";
export const PAUSE_TASK_BEGIN = "PAUSE_TASK_BEGIN";
export const PAUSE_TASK_SUCCESS = "PAUSE_TASK_SUCCESS";
export const PAUSE_TASK_INITIALIZE = "PAUSE_TASK_INITIALIZE";
export const STOP_TASK = "STOP_TASK";
export const STOP_TASK_BEGIN = "STOP_TASK_BEGIN";
export const STOP_TASK_SUCCESS = "STOP_TASK_SUCCESS";
export const STOP_TASK_INITIALIZE = "STOP_TASK_INITIALIZE";

// Report actions
export const FETCH_REPORTS = "FETCH_REPORTS";
export const FETCH_REPORTS_BEGIN = "FETCH_REPORTS_BEGIN";
export const FETCH_REPORTS_SUCCESS = "FETCH_REPORTS_SUCCESS";
export const DELETE_REPORT = "DELETE_REPORT";
export const DELETE_REPORT_BEGIN = "DELETE_REPORT_BEGIN";
export const DELETE_REPORT_SUCCESS = "DELETE_REPORT_SUCCESS";
export const DELETE_REPORT_ERROR = "DELETE_REPORT_ERROR";
export const CREATE_REPORT = "CREATE_REPORT";
export const CREATE_REPORT_BEGIN = "CREATE_REPORT_BEGIN";
export const CREATE_REPORT_SUCCESS = "CREATE_REPORT_SUCCESS";
export const CREATE_REPORT_ERROR = "CREATE_REPORT_ERROR";


// Description actions
export const FETCH_STAFF_TYPES = "FETCH_STAFF_TYPES";
export const FETCH_STAFF_TYPES_BEGIN = "FETCH_STAFF_TYPES_BEGIN";
export const FETCH_STAFF_TYPES_SUCCESS = "FETCH_STAFF_TYPES_SUCCESS";

// Vehicle actions
export const vehiclesTypes = {
  FETCH_VEHICLE_LIST: "FETCH_VEHICLE_LIST",
  FETCH_VEHICLE_LIST_BEGIN: "FETCH_VEHICLE_LIST_BEGIN",
  FETCH_VEHICLE_LIST_END: "FETCH_VEHICLE_LIST_END",
  CREATE_VEHICLE: "CREATE_VEHICLE",
  CREATE_VEHICLE_BEGIN: "CREATE_VEHICLE_BEGIN",
  CREATE_VEHICLE_SUCCESS: "CREATE_VEHICLE_SUCCESS",
  CREATE_VEHICLE_FAILED: "CREATE_VEHICLE_FAILED",
  RESET_VEHICLE: "RESET_VEHICLE",
};

// Personnel actions
export const personnelTypes = {
  FETCH_PERSONNEL_LIST: "FETCH_PERSONNEL_LIST",
  FETCH_PERSONNEL_LIST_BEGIN: "FETCH_PERSONNEL_LIST_BEGIN",
  FETCH_PERSONNEL_LIST_END: "FETCH_PERSONNEL_LIST_END",
  CREATE_PERSONNEL: "CREATE_PERSONNEL",
  CREATE_PERSONNEL_BEGIN: "CREATE_PERSONNEL_BEGIN",
  CREATE_PERSONNEL_SUCCESS: "CREATE_PERSONNEL_SUCCESS",
  CREATE_PERSONNEL_FAILED: "CREATE_PERSONNEL_FAILED",
  RESET_PERSONNEL: "RESET_PERSONNEL",
  CHECK_CHARGES: "CHECK_CHARGES",
  CHECK_CHARGES_END: "CHECK_CHARGES_END",

};

// Notification actions
export const FETCH_NOTIFICATIONS = "FETCH_NOTIFICATIONS";
export const FETCH_NOTIFICATIONS_BEGIN = "FETCH_NOTIFICATIONS_BEGIN";
export const FETCH_NOTIFICATIONS_END = "FETCH_NOTIFICATIONS_END";

export const SET_NOTIFICATIONS_READ = "SET_NOTIFICATIONS_READ";
export const SET_NOTIFICATIONS_READ_BEGIN = "SET_NOTIFICATIONS_READ_BEGIN";
export const SET_NOTIFICATIONS_READ_END = "SET_NOTIFICATIONS_READ_END";

export const APPEND_NOTIFICATIONS = "APPEND_NOTIFICATIONS";


export const FETCH_NOTIFICATION_SETTINGS = "FETCH_NOTIFICATION_SETTINGS";
export const FETCH_NOTIFICATION_SETTINGS_BEGIN = "FETCH_NOTIFICATION_SETTINGS_BEGIN";
export const FETCH_NOTIFICATION_SETTINGS_END = "FETCH_NOTIFICATION_SETTINGS_END";

export const EDIT_NOTIFICATION_SETTINGS = "EDIT_NOTIFICATION_SETTINGS";
export const EDIT_NOTIFICATION_SETTINGS_BEGIN = "EDIT_NOTIFICATION_SETTINGS_BEGIN";
export const EDIT_NOTIFICATION_SETTINGS_SUCCESS = "EDIT_NOTIFICATION_SETTINGS_SUCCESS";
export const EDIT_NOTIFICATION_SETTINGS_ERROR = "EDIT_NOTIFICATION_SETTINGS_ERROR";
