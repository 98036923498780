import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Popup from "./Popup";
import NotificationList from "../components/NotificationsTable";
import { fetchNotifications, setNotificationsRead, fetchNotificationSettings } from "../store/actions";
import ALARM_FALL_MP3 from "../static/fall.mp3"
import ALARM_BUTTON_MP3 from "../static/button.mp3"

function NotificationPopup() {
  const dispatch = useDispatch();
  const { notifications, alarmSettings } = useSelector((state) => state.notifications);
  const [showNotifications, setNotifications] = useState(false);
  const [toastNotification, setToastNotifications] = useState([]);
  const [newNotificationData, setNewNotificationData] = useState([]);

  const intervalNotifications = React.useRef(null);
  const alarmRef = React.useRef(null);
  const alarmSettingsRef = React.useRef(null);

  React.useEffect(() => {
    dispatch(fetchNotificationSettings())

    intervalNotifications.current = window.setInterval(
      () => dispatch(fetchNotifications()),
      30000
    );
  }, []);

  useEffect(() => {
    if (notifications.length) {
      setNewNotificationData(
        notifications.filter(
          (n) => n.notificationShowType === 1 && !n.isRead
        )
      );
      setToastNotifications(
        notifications.filter(
          (n) => n.notificationShowType === 2 && !n.isRead
        )
      );
    }
  }, [notifications]);


  useEffect(() => {
    if (newNotificationData.length) setNotifications(true);
    else setNotifications(false);
  }, [newNotificationData]);

  useEffect(() => {
    if (toastNotification.length) {
      let isFallAlarmActivated = false;
      let isButtonAlarmActivated = false;
      const alarm = alarmRef.current
      let onCloseFunc = () => null;
      alarm.loop = true;

      for (const i in toastNotification) {
        if (toastNotification.hasOwnProperty(i)) {
          let { data, autoClose, notifType, info, notificationShowType } = toastNotification[i];
          
          autoClose = autoClose === undefined ? 5000 : autoClose;
          notifType = notifType === undefined ? toast.TYPE.SUCCESS : notifType;

          // DUSME veya BUTON BILDIRIMYSE OTOMATIK KAPAMA VE UYARI SESI CAL
          if((info === "dusme" || info === "button") && parseInt(notificationShowType) === 2){
            autoClose = false;
            notifType = toast.TYPE.ERROR
            if(info === "dusme" && alarm && !isFallAlarmActivated){
              alarm.src = ALARM_FALL_MP3;
              alarm.currentTime = 0;
              alarm.play();
              isFallAlarmActivated = true;
              onCloseFunc = () => alarm.pause();
            }
            if(info === "button" && alarm && !isButtonAlarmActivated){
              alarm.src = ALARM_BUTTON_MP3;
              alarm.currentTime = 0;
              alarm.play();
              isButtonAlarmActivated = true;
              onCloseFunc = () => alarm.pause();
            }
          }

          toast(data, { type: notifType, autoClose: autoClose, onClose: onCloseFunc });
        }
      }
      setReadToastNotifications();
    }
  }, [toastNotification]);

  useEffect(() => {
    alarmSettingsRef.current = alarmSettings;
  }, [alarmSettings])

  const setReadToastNotifications = async () => {
    await dispatch(setNotificationsRead(toastNotification));
    await setToastNotifications([]);
  };

  const setReadAllNotifications = async () => {
    await dispatch(setNotificationsRead(newNotificationData));
    await setNotifications(false);
  };

  return (
    <>
      <Popup
        show={showNotifications && !!newNotificationData.length}
        title="Bildirimler"
        onClose={setReadAllNotifications}
      >
        {showNotifications && newNotificationData.length && (
          <NotificationList setReadNotifications={setReadAllNotifications} />
        )}
      </Popup>

      <audio ref={alarmRef} id="toast-fall-audio" />
    </>
  );
}

const NotificationWrapper = ({ children }) => {
  return (
    <>
      {children} <NotificationPopup />
    </>
  );
};

export default NotificationWrapper;
