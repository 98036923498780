import * as actionTypes from '../actions/types'

const initialState = {
    reportsLoading: false,
    reports: [],
}

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_REPORTS_BEGIN:
        case actionTypes.DELETE_REPORT_BEGIN:
        case actionTypes.CREATE_REPORT_BEGIN:
            return { ...state, reportsLoading: true }
        case actionTypes.FETCH_REPORTS_SUCCESS:
            return { ...state, reportsLoading: false, reports: action.payload, }

        default:
            return state
    }
}
