import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Popup from "../hoc/Popup";
import { Grid, InputAdornment } from "@material-ui/core";
import { StyledInput, StyledButton } from "../material-ui";
import { toast } from "react-toastify";
import { getAccessToken, fetchData } from "../utils";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { checkCharges, appendNotifications } from "../store/actions";
import ALARM_MP3 from "../static/alarm.mp3"

const dataTypes = {
  CONNECTED: "connected",
  LOGGEDIN: "success auth",
  INVALID_TOKEN: "error token invalid",
  ALARM_ENABLED: "success alarm enable",
  ALARM_DISABLED: "success alarm disable",
  ENABLED_DIFF_USER: "enable_alarm",
  DISABLED_DIFF_USER: "disable_alarm",
  STATUS_RINGING: "success alarm status enabled",
  STATUS_SILENCED: "success alarm status disabled",
  BUTTON_ALARM: "button_alarm",
};

const PersonnelNotifContent = ({ personnel, gatewayId }) => (
  <div style={{ padding: 5 }}>
    <h2 style={{ fontWeight: "bold" }}>Acil Durum - {gatewayId}</h2>
    <br />
    <p>
      {personnel.name} {personnel.surname}
    </p>
  </div>
);

const Alarm = ({visible}) => {
  const dispatch = useDispatch();
  const [client, setClient] = useState(new W3CWebSocket("wss://ratioback.com/websocket"));
  const [alarmConnected, setAlarmConnected] = useState(false);
  const [showAlarmModal, setShowAlarmModal] = useState(false);
  const [isRinging, setIsRinging] = useState(false);
  const [duration, setDuration] = useState();
  const { notificationSettings, alarmSettings } = useSelector(state => state.notifications)

  const personnelLastMessageTime = useRef({});
  const notificationSettingsRef = useRef(notificationSettings);
  const alarmSettingsRef = useRef(alarmSettings);
  const alarmRef = useRef(null);
  const isRingingRef = useRef(false);


  useEffect(() => {
    const TEN_MINUTES = 10 * 60 * 1000;
    notificationSettingsRef.current = notificationSettings;

    dispatch(checkCharges());
    setInterval(() => dispatch(checkCharges()), TEN_MINUTES);
  }, [notificationSettings])

  useEffect(() => {
    alarmSettingsRef.current = alarmSettings;
  }, [alarmSettings])

  // ALARM AUDIO CONFIG
  useEffect(() => {
    const alarm = alarmRef.current
    if(alarm){
      alarm.addEventListener('ended', function() {
        alarm.currentTime = 0;
        if(isRingingRef.current)
          alarm.play();
      }, false);
    }
  }, [alarmRef])

  useEffect(() => { isRingingRef.current = isRinging }, [isRinging])
  const pauseAlarmAudio = () => {
    alarmRef.current.pause();
    alarmRef.current.currentTime = 0;
  }


  const checkStatus = () => {
    client.send("alarm status");
  };

  const configureSocket = () => {
    client.onopen = () => {
      console.log("WebSocket Client Connected");
      client.send(`auth ${getAccessToken()}`);
    };

    client.onclose = () => {
        setClient(new W3CWebSocket("wss://ratioback.com/websocket"));
    };


    client.onmessage = async (message) => {
      const data = message.data
        .split(" ")
        .slice(0, 4)
        .filter((text) => isNaN(text))
        .join(" ");

      console.log(message.data);

      //TODO: REFACTOR NEW STATES

      //BUTTON ALARM
      if (data && data.split(" ")[0] === dataTypes.BUTTON_ALARM) {
        const gatewayId = message.data.split(" ")[1];
        const messageInterval =
          new Date() - personnelLastMessageTime.current[gatewayId];

        if (
          isNaN(messageInterval) ||
          messageInterval < 8500 ||
          messageInterval > 11500
        ) {
          const tag = await fetchData(`/staffTag?gatewayTagId=${gatewayId}`);
          if(!tag || !tag.length) return;
          
          let personnel = await fetchData(`/staff?staffTagId=${tag[0].id}`);
          personnel = personnel[0] || {} ;

          const notifType = parseInt(notificationSettingsRef.current && notificationSettingsRef.current.buttonAlarmNotificationType)

          const newNotification = {
            id: `button-alarm-${gatewayId}`,
            data: 
            notifType === 2 ? 
              <PersonnelNotifContent
                personnel={personnel}
                gatewayId={gatewayId}
              /> : `Acil Durum - ${gatewayId} - ${personnel.name} ${personnel.surname}` ,
            type: "staff",
            info: "button",
            notificationShowType: notifType || 2,
            notifType: toast.TYPE.ERROR,
            autoClose: false
          }

          dispatch(appendNotifications([newNotification]))
        }

        personnelLastMessageTime.current[gatewayId] = new Date();
      }

      if (data && data.split(" ")[0] === dataTypes.DISABLED_DIFF_USER){
        pauseAlarmAudio();
        return setIsRinging(false);
      }
      else if (data && data.split(" ")[0] === dataTypes.ENABLED_DIFF_USER) {
        setIsRinging(true);
        setTimeout(checkStatus, parseInt(message.data.split(" ")[2]));
        if(alarmSettingsRef.current.alarm)
          alarmRef.current.play();
      }

      switch (data) {
        case dataTypes.INVALID_TOKEN:
          console.log(data)
          break;
        case dataTypes.LOGGEDIN:
          setAlarmConnected(true);
          client.send("alarm status");
          break;
        case dataTypes.STATUS_RINGING:
          console.log(parseInt(message.data.split(" ")[4]));
          setTimeout(
            checkStatus,
            parseInt(message.data.split(" ")[4]) - new Date()
          );
          setIsRinging(true);
          alarmRef.current.play();
          break;
        case dataTypes.ALARM_ENABLED:
          setTimeout(checkStatus, parseInt(message.data.split(" ")[3]));
          setIsRinging(true);
          alarmRef.current.play();
          break;
        case dataTypes.ALARM_DISABLED:
        case dataTypes.STATUS_SILENCED:
          setIsRinging(false);
          pauseAlarmAudio();
          break;
      }
    };
  };
  
  useEffect(() => {
    configureSocket();

    return () => {
      if(client){
        client.onclose = () => {}
        client.close()
      }
    }
  }, [client]);

  const closeModal = () => {
    setShowAlarmModal(false);
  };

  const toggleAlarm = () => {
    if (isRinging) client.send(`alarm disable`);
    else client.send(`alarm enable ${duration * 1000}`);

    setShowAlarmModal(false);
  };

  return (
    <>
      <StyledButton
        className={`alarm-button ${isRinging ? "attention" : ""} ${visible ? "" : "hidden"}`}
        variant="outlined"
        color={alarmConnected ? "secondary" : "default"}
        onClick={() => setShowAlarmModal(true)}
        disabled={!alarmConnected}
      >
        Alarm
      </StyledButton>

      <Popup
        title={
          isRinging
            ? "Alarmı durdurmayı onaylıyor musunuz?"
            : "Alarm durumuna geçmeyi onaylıyor musunuz?"
        }
        show={showAlarmModal}
        onClose={closeModal}
      >
        <Grid justify="center" container>
          {isRinging || (
            <StyledInput
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">Saniye</InputAdornment>
                ),
              }}
              variant="outlined"
              type="number"
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
              fullWidth
            />
          )}
        </Grid>
        <Grid justify="center" container>
          <StyledButton variant="outlined" onClick={closeModal}>
            İptal
          </StyledButton>
          <StyledButton
            variant="outlined"
            color={isRinging ? "secondary" : duration ? "secondary" : "default"}
            disabled={isRinging ? false : !duration}
            onClick={toggleAlarm}
          >
            Onayla
          </StyledButton>
        </Grid>
      </Popup>
      <audio ref={alarmRef} id="alarm-audio" src={ALARM_MP3} preload="auto" />
    </>
  );
};

export default Alarm;