import * as actions from "../actions/types";

const initialState = {
  notifications: [],
  loading: false,
  error: null,

  notificationSettings: {
    staffNotificationType: "",
    operationNotificationType: "",
    vehicleNotificationType: "",
    batteryNotificationType: "",
    buttonAlarmNotificationType: "",
    //inactivityNotificationType: "",
    //personalFallNotificationType: "",
    //specialNotificationType: "",
  },
  alarmSettings: {
    personnelFall: false,
    alarm: false,
  },
  loadingSettings: true,
  errorSettings: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_NOTIFICATIONS_BEGIN:
      return { ...state, loading: true, error: null };
    case actions.FETCH_NOTIFICATIONS_END:
      return {
        ...state,
        loading: false,
        notifications: [
          ...state.notifications,
          ...action.payload.notifications.filter(a => !state.notifications.some(b => a.id === b.id )),
        ],
        error: null,
      };
    case actions.SET_NOTIFICATIONS_READ_END:
      return { ...state, loading: false, notifications: [], error: null };

    case actions.APPEND_NOTIFICATIONS:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          ...action.payload.filter(a => !state.notifications.some(b => a.id === b.id )).map((notif) => ({ ...notif, isRead: false })),
        ],
      };

    case actions.FETCH_NOTIFICATION_SETTINGS_BEGIN:
      return { ...state, loadingSettings: true, errorSettings: null };

    case actions.FETCH_NOTIFICATION_SETTINGS_END:
      return {
        ...state,
        notificationSettings: action.payload.notificationSettings,
        alarmSettings: action.payload.alarmSettings,
        loadingSettings: false,
        errorSettings: null,
      };

    case actions.EDIT_NOTIFICATION_SETTINGS_BEGIN:
      return {
        ...state,
        loadingSettings: true,
        errorSettings: null,
      };
    case actions.EDIT_NOTIFICATION_SETTINGS_SUCCESS:
      return {
        ...state,
        notificationSettings: action.payload,
        loadingSettings: false,
        errorSettings: null,
      };

    default:
      return state;
  }
};
