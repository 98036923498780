import * as actions from "../actions/types";

const initialState = {
  blockModelListLoading: false,
  blockModelList: [],
  totalBlockModels: 0,
  blockModelError: null,
  newBlockModel: null,

  subModelListLoading: false,
  subModelList: [],
  totalSubModels: 0,
  subModelError: null,

  selectedSubModelList: [],

  uploadSubModelLoading:false,
  uploadSubModelError:null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // FETCH
    case actions.FETCH_BLOCK_MODEL_LIST_BEGIN:
      return { ...state, blockModelListLoading: true, blockModelError: null };
    case actions.FETCH_BLOCK_MODEL_LIST_END:
      return {
        ...state,
        blockModelListLoading: false,
        blockModelList: action.payload,
        totalBlockModels: action.payload.length,
        blockModelError: null,
      };

    case actions.FETCH_SUB_MODEL_LIST_BEGIN:
      return { ...state, subModelListLoading: true, subModelError: null };
    case actions.FETCH_SUB_MODEL_LIST_END:
      return {
        ...state,
        subModelListLoading: false,
        subModelList: action.payload,
        totalSubModels: action.payload.length,
        subModelError: null,
      };

    case actions.SET_SELECTED_SUB_MODEL_LIST:
      return{
        ...state,
        selectedSubModelList: action.payload
      }

    // CREATE
    case actions.CREATE_BLOCK_MODEL_BEGIN:
      return { ...state, blockModelListLoading: true, blockModelError: null };
    case actions.CREATE_BLOCK_MODEL_SUCCESS:
      return {
        ...state,
        blockModelListLoading: false,
        blockModelError: null,
        newBlockModel: action.payload,
      };
    case actions.CREATE_BLOCK_MODEL_FAILED:
      return {
        ...state,
        blockModelListLoading: false,
        newBlockModel: null,
        blockModelError: "Blok Model Oluşturulamadı!",
      };

    case actions.RESET_BLOCK_MODEL:
      return { ...state, blockModelError: null, newBlockModel: null };
      
      // UPLOAD FILE
      case actions.UPLOAD_SUB_MODEL_BEGIN:
        return { ...state, uploadSubModelLoading: true, uploadSubModelError: null };
      case actions.UPLOAD_SUB_MODEL_SUCCESS:
        return {
          ...state,
          uploadSubModelLoading: false,
          uploadSubModelError: null,
        };
      case actions.UPLOAD_SUB_MODEL_FAILED:
        return {
          ...state,
          uploadSubModelLoading: false,
          uploadSubModelError: action.payload ||"Dosya Gönderilemedi!",
        };
  
      case actions.RESET_UPLOAD_SUB_MODEL:
        return { ...state, uploadSubModelError: null };

    default:
      return state;
  }
};
