import * as actions from './types'
export const fetchBlockModelList = () => ({ type: actions.FETCH_BLOCK_MODEL_LIST })
export const fetchBlockModelListBegin = () => ({ type: actions.FETCH_BLOCK_MODEL_LIST_BEGIN })
export const fetchBlockModelListEnd = payload => ({ type: actions.FETCH_BLOCK_MODEL_LIST_END, payload })

export const fetchSubModelList = blockModelId => ({ type: actions.FETCH_SUB_MODEL_LIST, payload: { blockModelId } })
export const fetchSubModelListBegin = () => ({ type: actions.FETCH_SUB_MODEL_LIST_BEGIN })
export const fetchSubModelListEnd = payload => ({ type: actions.FETCH_SUB_MODEL_LIST_END, payload })

export const setSelectedSubModelList = subModelList => ({ type: actions.SET_SELECTED_SUB_MODEL_LIST, payload: subModelList })

export const createBlockModel = blockModelConfig => ({ type: actions.CREATE_BLOCK_MODEL, payload: { blockModelConfig } })
export const createBlockModelBegin = () => ({ type: actions.CREATE_BLOCK_MODEL_BEGIN })
export const createBlockModelSuccess = newBlockModel => ({ type: actions.CREATE_BLOCK_MODEL_SUCCESS, payload: newBlockModel })
export const createBlockModelFailed = () => ({ type: actions.CREATE_BLOCK_MODEL_FAILED })
export const resetBlockModel = () => ({ type: actions.RESET_BLOCK_MODEL })

export const uploadSubModel = (blockModelId, file) => ({ type: actions.UPLOAD_SUB_MODEL, payload: { blockModelId,file } })
export const uploadSubModelBegin = () => ({ type: actions.UPLOAD_SUB_MODEL_BEGIN })
export const uploadSubModelSuccess = () => ({ type: actions.UPLOAD_SUB_MODEL_SUCCESS })
export const uploadSubModelFailed = message => ({ type: actions.UPLOAD_SUB_MODEL_FAILED, payload: message })
export const resetUploadSubModel = () => ({ type: actions.RESET_UPLOAD_SUB_MODEL })

