import React from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { ToastContainer, Slide } from 'react-toastify'
import { theme, StyledPage, GlobalStyle } from './components/styles/Theme'
import PrivateRoute from './hoc/PrivateRoute'
import WithLayout from './hoc/withLayout'
import Spinner from './components/Spinner'
import 'react-toastify/dist/ReactToastify.min.css'
import NotificationWrapper from './hoc/Notifications'
import { useCookies } from 'react-cookie';
import { removeAuth, loginSuccess } from './store/actions'

const Login = React.lazy(() => import('./components/Login'))
const Home = React.lazy(() => import('./components/Home'))
const Map = React.lazy(() => import('./components/Map'))
const BlockModel = React.lazy (()=>import( './components/BlockModel'))
const Tasks = React.lazy(() => import('./components/Tasks'))
const Gems = React.lazy(() => import('./components/Gems'))
const Reports = React.lazy(() => import('./components/Reports'))
const Notifications = React.lazy(() => import('./components/Notifications'))
const Settings = React.lazy(() => import('./components/Settings/index'))
const Metrics = React.lazy(() => import('./components/Metrics'))
const GemChart = React.lazy(() => import('./components/GemChart'))

const Personnels = React.lazy(() => import('./components/Personnels'))
const OperatorDrillDetail = React.lazy(() => import('./components/PersonnelDetail/OperatorDrillDetail'))
const OperatorDetail = React.lazy(() => import('./components/PersonnelDetail/OperatorDetail'))
const DriverDetail = React.lazy(() => import('./components/PersonnelDetail/DriverDetail'))
const MissionDetail = React.lazy(() => import('./components/MissionDetail'))

const Vehicles = React.lazy(() => import('./components/Vehicles'))
const TruckLoaderDetail = React.lazy(() => import('./components/VehicleDetail/TruckLoaderDetail'))
const ExcavatorDetail = React.lazy(() => import('./components/VehicleDetail/ExcavatorDetail'))
const DrillDetail = React.lazy(() => import('./components/VehicleDetail/DrillDetail'))
const DefaultVehicleDetail = React.lazy(() => import('./components/VehicleDetail/DefaultVehicleDetail'))

export default () => {
    const authenticated = useSelector(state => state.auth.authenticated)
    const [cookies,setCookie, removeCookie] = useCookies(['token']);
    const dispatch = useDispatch();

    const logout = ()=>{
        localStorage.removeItem("token-ratio");
        localStorage.removeItem("token-ratio-expires");
        localStorage.removeItem('user-ratio');
        removeCookie("token");
        dispatch(removeAuth());
        return (<Redirect to="/login" />);
    }

    React.useEffect(()=>{
        if(cookies.token && cookies.token.access_token){
            dispatch(loginSuccess(cookies.token.access_token, cookies.token.expires_in));
        }else{
            dispatch(removeAuth());
        }

    },[cookies]);

    React.useEffect(()=>{
        if(authenticated)
            setCookie("token", {
                access_token: localStorage.getItem("token-ratio"),
                expires_in: localStorage.getItem("token-ratio-expires")
            }, {path: "/", expires: new Date(+new Date() + Number(localStorage.getItem("token-ratio-expires")))});
    },[authenticated]);

    const loginRender = () => {
        if(!authenticated){
            console.log("Showing login page");
            return (<Login />);
        }
        console.log("redirecting root");
        return (<Redirect to="/" />);
    }

    return (
        <ThemeProvider theme={theme}>
            <StyledPage>
                <Router>
                    <React.Suspense fallback={<Spinner />}>
                        <Switch>
                            <Route path="/login" render={loginRender} />
                            <Route path="/logout" render={() => logout()} />
                            <NotificationWrapper>
                                <WithLayout>    
                                    <PrivateRoute path="/" exact component={Home} />
                                    <PrivateRoute path="/map" component={Map} />
                                    <PrivateRoute path="/tasks" component={Tasks} />
                                    <PrivateRoute path="/gem" component={Gems} />
                                    <PrivateRoute path="/gemchart/:id" component={GemChart} />
                                    <PrivateRoute path="/blockmodel" component={BlockModel} />
                                    <PrivateRoute path="/personnels" exact component={Personnels} />
                                    <PrivateRoute path="/personnels/detail/operatordrill" component={OperatorDrillDetail} />
                                    <PrivateRoute path="/personnels/detail/operator" component={OperatorDetail} />
                                    <PrivateRoute path="/personnels/detail/driver" component={DriverDetail} />
                                    <PrivateRoute path="/personnels/detail/mission" component={MissionDetail} />
                                    <PrivateRoute path="/vehicles" exact component={Vehicles} />
                                    <PrivateRoute path="/vehicles/detail/truck" component={TruckLoaderDetail} />
                                    <PrivateRoute path="/vehicles/detail/loader" component={TruckLoaderDetail} />
                                    <PrivateRoute path="/vehicles/detail/excavator" component={ExcavatorDetail} />
                                    <PrivateRoute path="/vehicles/detail/drill" component={DrillDetail} />
                                    <PrivateRoute path="/vehicles/detail/other" component={DefaultVehicleDetail} />
                                    <PrivateRoute path="/reports" component={Reports} />
                                    <PrivateRoute path="/notifications" component={Notifications} />
                                    <PrivateRoute path="/settings" component={Settings} />
                                    <PrivateRoute path="/metrics" component={Metrics} />
                                </WithLayout>
                            </NotificationWrapper>
                            <Redirect to="/not-found" />
                        </Switch>
                    </React.Suspense>
                </Router>
                <ToastContainer hideProgressBar autoClose={1500} transition={Slide} />
                <GlobalStyle />
            </StyledPage>
        </ThemeProvider>
    )
}
